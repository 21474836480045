<template>
  <v-row no-gutters>
    <v-col cols="12" md="4">
      <v-card class="sc-chat-window" border>
        <!-- <pre>
          {{AllUsers}}
        </pre> -->
        <v-list shaped dense class="py-3">
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(user, index) in AllUsers"
              :key="index"
              @click="initChat(user) "
            >
              <v-list-item-avatar size="32">
                <v-img
                  :src="user.photo ? `${apiUrl}/${user.photo}` : `/avatar.png`"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="username(user.name)"> </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-badge
                  v-if="countReads(user.id)"
                  :content="
                    newMessage ? countReads(user.id) - 1 : countReads(user.id)
                  "
                  color="red"
                >
                  <v-icon medium>
                    mdi-bell
                  </v-icon>
                </v-badge>
                <v-icon medium v-else>
                  mdi-bell
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="12" md="8">
      <suportChat v-if="chat" :users="user" @back="close"></suportChat>
    </v-col>
  </v-row>
</template>

<script>
import suportChat from "./suportChat";
import { USERS } from "./../graphql/Query";
import { mapGetters } from "vuex";
import { API_URL } from "./../../../../../api";
import { CHATS_ROOM_QUERY } from "./../graphql/Query";
import {
  MESSAGE_SENT_SUBSCRIPTION,
  UPDATED_COUNT_CHAT,
} from "./../graphql/subscription";
export default {
  name: "Chat",
  components: {
    suportChat,
  },
  data() {
    return {
      apiUrl: API_URL + "/images/user/",
      user: [],
      AllUsers: [],
      chat: false,
      chats_: [],
      newMessage: false,
      flag: 1,
    };
  },
  apollo: {
    users: {
      query: USERS,
      fetchPolicy: "no-cache",
      result({ data }) {
        console.log('users',data);
        data.users.forEach((element) => {
          if (
            (element.id != this.currentUser.id && element.type === "partner") ||
            element.type === "entity"
          ) {
            this.AllUsers.push(element);
          }
        });
      },
    },
    recivedChats: {
      query: CHATS_ROOM_QUERY,
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.recivedChats) {
          this.loadMessages = true;
          console.log("chats_", data.recivedChats);
          this.chats_ = data.recivedChats;
        }
      },
    },
    $subscribe: {
      addChat: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        result({ data }) {
          if (this.flag === 1) {
            if (data.addChat.messages.author.id != this.currentUser.id) {
              var index = this.chats_.findIndex(
                (u) => u.room == data.addChat.name
              );
              if (index > -1) {
                this.chats_[index].messages.push(data.addChat.messages);
              } else {
                this.chats_.push({
                  room: data.addChat.name,
                  messages: [data.addChat.messages],
                });
              }
            }
          }
        },
      },
      updateCountChat: {
        query: UPDATED_COUNT_CHAT,
        result({ data }) {
          if (this.flag === 1) {
            var index = this.chats_.findIndex(
              (u) => u.room == data.updateCountChat.room
            );
            if (index > -1) {
              this.chats_[index].messages = data.updateCountChat.messages;
            }
          }
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
   
  },
  methods: {
      username(username) {
      username = username.split(" ");
      return username[0] + ' '+username[username.length-1];
    },
    countReads(id) {
      var sum = 0;
      this.chats_.forEach((element) => {
        if (element.room === id) {
          element.messages.forEach((msg) => {
            if (msg.read === "1" && msg.author.id === id) {
              sum = sum + 1;
            }
          });
        }
      });

      return sum;
    },

    initChat(user) {
      // this.chat = !this.chat;
      this.user = user;
      this.chats_.forEach((element) => {
        if (element.room === user.id) {
          if (this.chat) {
            this.chat = true;
          } else {
            this.chat = true;
          }
        }
      });
    },
    close() {
      this.chat = false;
    },
  },
};
</script>
